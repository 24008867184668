@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .content > * {
    grid-column: content;
  }

  .prose p:empty {
    display: none;
  }

  html, body, #__next {
    @apply bg-neutral-1 text-accent-12;
    min-height: 100%;
    max-width: 100vw;
  }
}

@layer components {
  /* https://ryanmulligan.dev/blog/layout-breakouts/ */
  .content {
    --gap: clamp(0.5rem, 6vw, 3rem);
    --full: minmax(var(--gap), 1fr);
    --content: min(65ch, 100% - var(--gap) * 2);
    --popout: minmax(0, 4rem);
    --feature: minmax(0, 8rem);

    display: grid;
    grid-template-columns:
      [full-start] var(--full)
      [feature-start] var(--feature)
      [popout-start] var(--popout)
      [content-start] var(--content) [content-end]
      var(--popout) [popout-end]
      var(--feature) [feature-end]
      var(--full) [full-end];
  }

  dl {
    @apply md:grid gap-y-1 gap-x-2;
    grid-template-columns: minmax(max-content, auto) 1fr;
    grid-template-areas: "term definition";
  }
  dt {
    font-weight: bold;
    grid-column: term;
  }
  dd {
    grid-column: definition;
  }

  .ClampFade {
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ClampFade:after {
    @apply absolute inset-x-0 bottom-0;
    content: '';
    height: 60px;
    background: linear-gradient(transparent, white);
  }
  @media (prefers-color-scheme: dark) {
    .ClampFade:after {
      background: linear-gradient(transparent, black);
    }
  }
}

@layer utilities {
  .bg-neutral-0, .bg-accent-0 {
    background-color: white;
  }
  @media (prefers-color-scheme: dark) {
    .bg-neutral-0, .bg-accent-0 {
      background-color: black;
    }
  }
  .text-neutral-0, .text-accent-0 {
    color: white;
  }
  @media (prefers-color-scheme: dark) {
    .text-neutral-0, .text-accent-0 {
      color: black;
    }
  }
}

.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  flex-wrap: wrap !important;
}
html,
body {
  --ck-border-radius: .5rem;
  --ck-color-base-border: #f1465d;
  --ck-color-toolbar-border: #f1465d;
}

.PopoverContent {
  /* width: var(--radix-popper-anchor-width); */
  max-height: var(--radix-popover-content-available-height);
}

.CollapsibleContent {
  overflow: hidden;
}
.CollapsibleContent[data-state='open'] {
  animation: slideDown 300ms ease-out;
}
.CollapsibleContent[data-state='closed'] {
  animation: slideUp 300ms ease-out;
}
@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}
@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.scrollbar {
  @apply scrollbar-thin scrollbar-track-accent-6 scrollbar-thumb-neutral-11/40 hover:scrollbar-thumb-neutral-11/70;
}

.glider-contain {
  grid-column: full;
}
.glider-dots {
  margin-top: 1rem !important;
}
.glider-dot {
  border-radius: 0;
  width: 50px;
  height: 9px;
}
.glider-dot.active {
  background-color: theme('colors.primary');
}
.glider-prev,
.glider-next {
  position: absolute;
}
.glider-prev {
  left: 1rem;
  top: calc(50% - 2rem);
}
.glider-next {
  right: 1rem;
  top: calc(50% - 2rem);
}


.animated.circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash 0.9s ease-in-out;
}
.animated.check {
  stroke-dasharray: 1000;
  stroke-dashoffset: -100;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
