.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-off-range {
  color: #999999;
}
.rbc-off-range-bg {
  background: #e6e6e6;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  @apply divide-x divide-[#ddd];
  border-bottom: 1px solid #ddd;
}
.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-button-link {
  color: inherit;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: text;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  z-index: 4;
}

.rbc-today {
  @apply bg-accent-3;
}

.rbc-event {
  font-size: 90%;
  padding: 2px 5px;
  background-color: #00B9DE;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  text-align: left;
}
.rbc-event.rbc-selected {
  background-color: #008fab;
}
.rbc-event:focus {
  outline: 5px auto #3b99fc;
}
.rbc-event.empty-event {
  background: repeating-linear-gradient(
    35deg,
    rgba(0, 185, 222, .85),
    rgba(0, 185, 222, .85) 5px,
    rgba(0, 185, 222, .9) 5px,
    rgba(0, 185, 222, .9) 10px
  );
}
.rbc-event.is-group {
  background: HSL(352, 86%, 61%);
}

.rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}

.rbc-event-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 80%;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-row-segment .rbc-event-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  color: #00B9DE;
}
.rbc-show-more:hover, .rbc-show-more:focus {
  color: #008fab;
}

.rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
  @apply divide-y divide-[#ddd];
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}

.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  @apply absolute flex inset-0 overflow-hidden;
  @apply divide-x divide-[#ddd];
  flex: 1 0 0;
}

.rbc-day-bg {
  flex: 1 0 0;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #008fab;
  max-height: 100%;
  min-height: 20px;
  position: absolute;
  overflow: hidden;
}
.rbc-day-slot .rbc-event-label {
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  word-wrap: break-word;
  line-height: 1;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter {
  @apply bg-neutral-1;
  position: sticky;
  left: 0;
  border-right: 1px solid #ddd;
  z-index: 10;
  margin-right: -1px;
}
.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0px;
}
.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
.rbc-time-view-resources .rbc-header, .rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex:  1 1 0px;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
  min-height: 16px;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 0;
  overflow-x: auto;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
  text-align: right;
}
.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto; /* should not shrink below height */
  flex-direction: row;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #ddd;
}
.rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  user-select: none;
}

.rbc-drag-row {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.rbc-nondraggable {
  background-color: #abf1ff !important;
  color: black !important;
}

.rbc-dragged-event {
  opacity: 0;
}
.rbc-is-dragging .rbc-event:not(.rbc-dragged-event):not(.rbc-drag-preview) {
  opacity: .50;
}

.rbc-resizable {
  position: relative;
  width: 100%;
  height: 100%;
}
